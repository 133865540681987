<template>
  <div class="content-wrapper">
    <div class="d-flex tag-wrapper">
      <span
        class="tag-item"
        :class="this.$route.query.cat === 'all' ? 'tag-active' : null"
        ><a href="javascript:;" @click="fetchData">전체</a></span
      >
      <span
        class="tag-item"
        :class="this.$route.query.cat === 'attractions' ? 'tag-active' : null"
        ><a href="javascript:;" @click="onClickAttraction">명소</a></span
      >
      <span
        class="tag-item"
        :class="this.$route.query.cat === 'cafes' ? 'tag-active' : null"
        ><a href="javascript:;" @click="onClickCafe">카페</a></span
      >
      <span
        class="tag-item"
        :class="this.$route.query.cat === 'beaches' ? 'tag-active' : null"
        ><a href="javascript:;" @click="onClickBeach">해변</a></span
      >
      <span
        class="tag-item"
        :class="this.$route.query.cat === 'hotels' ? 'tag-active' : null"
        ><a href="javascript:;" @click="onClickHotel">호텔</a></span
      >
      <span
        class="tag-item"
        :class="this.$route.query.cat === 'activities' ? 'tag-active' : null"
        ><a href="javascript:;" @click="onClickActivity">액티비티</a></span
      >
    </div>
    <div id="map"></div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      map: null,
      markers: [],
      infowindow: null,
    };
  },
  computed: {
    ...mapState(['maps', 'isOk']),
  },
  watch: {
    isOk() {
      this.initMap();
    },
    maps() {
      this.initMap();
    },
  },
  created() {
    this.fetchData();
  },
  mounted() {
    window.kakao && window.kakao.maps
      ? this.initMap()
      : this.addKakaoMapScript();
  },
  methods: {
    addKakaoMapScript() {
      const script = document.createElement('script');
      /* global kakao */
      script.onload = () => kakao.maps.load(this.initMap);
      script.src =
        '//dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=6c27d38503ea42c4b2aa0595526c76a6';
      document.head.appendChild(script);
    },
    initMap() {
      const container = document.getElementById('map');
      const options = {
        center: new kakao.maps.LatLng(33.3942, 126.5268),
        level: 10,
      };
      this.map = new kakao.maps.Map(container, options);
      // 마커가 표시될 위치입니다
      // var markerPosition = new kakao.maps.LatLng(33.3942, 126.5268);
      // 마커를 생성합니다
      // var marker = new kakao.maps.Marker({
      //   position: markerPosition,
      // });

      for (var i = 0; i < this.maps.length; i++) {
        var arrLatLng = this.maps[i].geo_pos.split(',');
        var lat = parseFloat(arrLatLng[0]);
        var lng = parseFloat(arrLatLng[1]);
        // var spotName = this.maps[i].name;
        // 마커에 표시할 인포윈도우를 생성합니다
        var infowindow = new kakao.maps.InfoWindow({
          content: `<div style="border-radius: 3px;text-align:center;background-color:#333;color:#fff;"><div><img src="${this.maps[i].thumbnail}" width="150" /></div><div>${this.maps[i].name}</div></div>`, // 인포윈도우에 표시할 내용
        });

        this.markers = new kakao.maps.Marker({
          map: this.map, // 마커를 표시할 지도
          position: new kakao.maps.LatLng(lat, lng), // 마커를 표시할 위치
        });

        // 마커에 mouseover 이벤트와 mouseout 이벤트를 등록합니다
        // 이벤트 리스너로는 클로저를 만들어 등록합니다
        // for문에서 클로저를 만들어 주지 않으면 마지막 마커에만 이벤트가 등록됩니다
        new kakao.maps.event.addListener(
          this.markers,
          'mouseover',
          makeOverListener(this.map, this.markers, infowindow)
        );
        new kakao.maps.event.addListener(
          this.markers,
          'mouseout',
          makeOutListener(infowindow)
        );
        new kakao.maps.event.addListener(
          this.markers,
          'click',
          makeClickListener(this.maps[i].id)
        );
      }
      // 인포윈도우를 표시하는 클로저를 만드는 함수입니다
      function makeOverListener(map, marker, infowindow) {
        return function () {
          infowindow.open(map, marker);
        };
      }

      // 인포윈도우를 닫는 클로저를 만드는 함수입니다
      function makeOutListener(infowindow) {
        return function () {
          infowindow.close();
        };
      }

      function makeClickListener(id) {
        return function () {
          location.href = `/destination/${id}`;
        };
      }
      // 마커가 지도 위에 표시되도록 설정합니다
      this.markers.setMap(this.map);
    },
    ...mapActions(['FETCH_MAPS', 'FETCH_MAP_CATS']),
    fetchData() {
      this.FETCH_MAPS();
      this.$router.replace({ path: '/map?cat=all' });
    },
    onClickAttraction() {
      this.FETCH_MAP_CATS('명소');
      this.$router.replace({ path: '/map?cat=attractions' }).catch(() => {});
    },
    onClickCafe() {
      this.FETCH_MAP_CATS('카페');
      this.$router.replace({ path: '/map?cat=cafes' });
    },
    onClickBeach() {
      this.FETCH_MAP_CATS('해변');
      this.$router.replace({ path: '/map?cat=beaches' });
    },
    onClickHotel() {
      this.FETCH_MAP_CATS('호텔');
      this.$router.replace({ path: '/map?cat=hotels' });
    },
    onClickActivity() {
      this.FETCH_MAP_CATS('액티비티');
      this.$router.replace({ path: '/map?cat=activities' });
    },
  },
};
</script>

<style scoped>
.tag-wrapper {
  margin-bottom: 35px;
}
#map {
  width: 744px;
  height: 700px;
}
.tag-item {
  display: inline-block;
  margin-right: 3px;
  margin-bottom: 3px;
  cursor: pointer;
}
.tag-item::after {
  content: ' | ';
  padding-right: 15px;
  padding-left: 15px;
  color: #bababa;
}
.tag-item:last-child:after {
  content: '';
}
.tag-item > a {
  display: inline-block;
  text-decoration: none;
  color: #333;
  font-size: 18px;
}
.tag-active > a {
  color: #44cac8;
}
@media screen and (max-width: 767px) {
  .tag-item > a {
    font-size: 14px;
  }
  .tag-item::after {
    padding-left: 5px;
    padding-right: 5px;
  }
  #map {
    width: 100%;
    height: 400px;
  }
}
</style>
